<template>
    <BT-Blade-Items
        addBladeName="customer-group"
        bladeName="customer-groups"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Name', value: 'groupName', title: 1, searchable: true }]"
        navigation="customer-groups"
        title="Customer Groups"
        useServerPagination />
</template>

<script>
export default {
    name: 'Customer-Groups',
    props: {
        bladesData: null
    }
}
</script>